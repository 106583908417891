import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Layout from "src/components/Layout"
import PageHero from "src/components/PageHero"
import Content from "src/components/Content"

import "./pageTemplate.scss"

const PageTemplate = ({data: {page: {frontmatter: {
  meta, hero
}, html}}}) => (
  <Layout blueHeader={hero === null} meta={meta}>
    <div className="page-template">
      {hero ? (
        <PageHero {...hero} />
      ) : (
        <section className="empty-hero" />
      )}
      <div id="content" className="page-content container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <Content html={html} />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

PageTemplate.defaultProps = {
  data: {
    page: {
      frontmatter: {
        meta: {},
        hero: {},
      },
      html: "",
    },
  },
}

PageTemplate.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      frontmatter: PropTypes.shape({
        meta: PropTypes.object,
        hero: PropTypes.object,
      }),
      html: PropTypes.string,
    }),
  }),
}

export default PageTemplate

export const pageQuery = graphql`
  query($path: String!) {
    page: markdownRemark(frontmatter: { meta: { path: { eq: $path } } }) {
      html
      ...MetaFragment
      ...HeroFragment
    }
  }
`
